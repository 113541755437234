//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    show: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    tempPass: { type: String, default: '' },
  },

  methods: {
    close() {
      this.$emit('close');
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.$props.tempPass);
      this.close();
    },
  },
};
