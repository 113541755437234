//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import constant from '~/const';
import Filters from './AdminManagementAdminsFilters.vue';
import Extra from './AdminManagementAdminsExtra.vue';
import CreateModal from './AdminManagementAdminsCreateModal.vue';
import SavePassModal from './AdminManagementSavePassModal.vue';

import tableConfig from './tableConfig';

export default {

  components: {
    Filters, Extra, CreateModal, SavePassModal,
  },

  data() {
    return {
      tableConfig,
      selection: [],
      filteredData: [],
      loader: false,
      createAdminModal: {
        show: false,
        loading: false,
      },
      savePassModal: {
        show: false,
        tempPass: '',
      },
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('adminManagementAdmins', ['page', 'pages', 'data', 'sort']),

    getFilteredData() {
      return this.filteredData.length === 0 ? this.data : this.filteredData;
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('adminManagementAdmins', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      createAdminAction: 'createAdmin',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    filterAction(action) {
      switch (action) {
        case 'active':
          this.filteredData = this.data.filter((e) => !e.blocked);
          break;
        case 'blocked':
          this.filteredData = this.data.filter((e) => e.blocked);
          break;
        case 'reset':
          this.filteredData = this.data;
          break;
        default:
          this.filteredData = this.data;
          break;
      }
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    getStatus(id) {
      const { ADMIN_STATUS_LIST } = constant.admin;
      return this.$utils.getCaption(ADMIN_STATUS_LIST, id);
    },

    async createAdmin(params) {
      this.createAdminModal.loading = true;
      this.setGeneralProgress(true);
      try {
        const response = await this.createAdminAction(params);
        const { tempPassword } = response.data;
        await this.loadDataAction();
        this.createAdminModal.show = false;
        this.setSuccessNotification('Administrator successfully created');
        this.savePassModal.tempPass = tempPassword;
        this.savePassModal.show = true;
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.createAdminModal.loading = false;
      this.setGeneralProgress(false);
    },
  },
};
