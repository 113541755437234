var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin-management-log"},[_c('div',{staticClass:"admin-management-log__filters"},[_c('Filters',{attrs:{"is-loading":_vm.generalLoader},on:{"update":_vm.applyFilters}})],1),_c('div',{staticClass:"admin-management-log__table"},[_c('UiTable',{attrs:{"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"527px","show-extra":""},on:{"sort":_vm.applySort},scopedSlots:_vm._u([{key:"dateCreated",fn:function(ref){
var row = ref.row;
return [(_vm.$utils.getAppType() !== 'p2pay')?_c('div',[_vm._v(" "+_vm._s(_vm.$utils.getDate(row.dateCreated))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.$utils.getTime(row.dateCreated))+" ")])]):_c('div',[_vm._v(" "+_vm._s(_vm.$utils.getUnixDate(row.created))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.$utils.getUnixTime(row.created))+" ")])])]}},{key:"admin",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"d-flex flex-column"},[_vm._v(" "+_vm._s(row.admin.email)+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.$utils.getCaption(_vm.constant.admin.ADMIN_STATUS_LIST, row.admin.type))+" ")])])]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('pre',_vm._g(_vm._b({class:{
                'text-truncate': true,
                'success--text': row.successful,
                'error--text': !row.successful,
              }},'pre',attrs,false),on),[_vm._v(_vm._s(row.method))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(row.method))])])]}},{key:"comment",fn:function(ref){
              var row = ref.row;
return [_vm._v(" "+_vm._s(row.comment)+" ")]}},{key:"extra",fn:function(ref){
              var row = ref.row;
return [_c('div',[_c('v-card',{staticClass:"my-3"},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('Request parameters')))]),_c('v-card-text',[_c('v-list',_vm._l((_vm.parseRowData(row)),function(value,key){return _c('v-list-item',{key:key,staticStyle:{"min-height":"40px"}},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',{staticClass:"text-body-2"},[_c('span',{staticClass:"text--secondary mr-2"},[_vm._v(_vm._s(key)+":")]),_vm._v(" "+_vm._s(value)+" ")])],1)],1)}),1)],1)],1),(row.errorCode)?_c('v-card',{staticClass:"my-3"},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('Request error'))+": "+_vm._s(row.errorCode))]),_c('v-card-text',[_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.$t(("errors." + (row.errorMessage)))))])])],1):_vm._e()],1)]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }