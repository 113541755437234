//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import constant from '~/const';

export default {
  props: {
    data: { type: Object, required: true },
  },

  data() {
    return {
      loader: false,
      loaderTelegramCheckboxes: true,
      emailNotificationTypeList: [],
      telegramNotificationTypeList: [],
      telegramId: '',
      constant,
    };
  },

  computed: {
    ...mapState('login', ['currentAdmin']),
    ...mapState('adminManagementAdmins', ['adminNotificationsSettings']),

    statusList: () => constant.admin.ADMIN_STATUS_LIST,
    adminNotificationTypeList() {
      if (this.$utils.getAppType() === 'p2pay') {
        return constant.admin.ADMIN_NOTIFICATION_TYPE_P2PAY_LIST;
      }
      return constant.admin.ADMIN_NOTIFICATION_TYPE_LIST;
    },

    currentAdminNotificationsSettings() {
      const { data, adminNotificationsSettings } = this;
      return adminNotificationsSettings.filter((e) => e.adminId === data.id);
    },

    currentAdminTelegramId: {
      get() {
        const { currentAdminNotificationsSettings } = this;
        if (currentAdminNotificationsSettings) {
          return this.getNotifications('telegram').telegramId || null;
        }

        return '';
      },
      set(telegramId) {
        this.telegramId = telegramId;
      },
    },
  },

  watch: {
    telegramId(value) {
      this.loaderTelegramCheckboxes = true;
      if (value.length) this.loaderTelegramCheckboxes = false;
    },
  },

  created() {
    const { currentAdminNotificationsSettings } = this;
    if (currentAdminNotificationsSettings) {
      this.telegramNotificationTypeList = this.getNotifications('telegram').adminNotificationTypeList || this.getNotifications('telegram').types || [];
      this.emailNotificationTypeList = this.getNotifications('email').adminNotificationTypeList || this.getNotifications('email').types || [];
    }

    if (this.currentAdminTelegramId !== null) {
      this.loaderTelegramCheckboxes = false;
    }
  },

  methods: {
    ...mapActions('common', [
      'confirmAction',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
    ]),
    ...mapActions('adminManagementAdmins', {
      changeAdminStatusAction: 'changeAdminStatus',
      loadAdminManagementAdmins: 'loadData',
      blockAdminAction: 'blockAdmin',
      unblockAdminAction: 'unblockAdmin',
      loadAdminNotificationsSettings: 'loadAdminNotificationsSettings',
      removeAdminNotificationsSettingsAction: 'removeAdminNotificationsSettings',
      addAdminNotificationsSettingsAction: 'addAdminNotificationsSettings',
      editAdminNotificationsSettingsAction: 'editAdminNotificationsSettings',
    }),

    getNotifications(type) {
      const { currentAdminNotificationsSettings } = this;
      if (type === 'telegram') return currentAdminNotificationsSettings.find((e) => e.telegramId !== null && e.telegramId !== undefined) || [];
      if (type === 'email') return currentAdminNotificationsSettings.find((e) => e.email !== null && e.email !== undefined) || [];

      return null;
    },

    confirmChangeStatus(value) {
      const { id } = this.data;
      this.confirmAction({
        text: 'Are you sure you want to change status?',
        callback: async () => {
          this.loader = true;
          this.setGeneralProgress(true);

          await this.changeAdminStatusAction({
            AdminId: id,
            Status: value,
          });
          await this.loadAdminManagementAdmins();

          this.setSuccessNotification('Status successfully changed');
          this.loader = false;
          this.setGeneralProgress(false);
        },
      });
    },

    confirmBlockAdmin() {
      const { id } = this.data;
      const currentId = this.currentAdmin.id;
      if (id === currentId) {
        this.setErrorNotification('You cannot block yourself');
        return;
      }
      this.confirmAction({
        text: 'Are you sure you want to block admin?',
        callback: async () => {
          this.loader = true;
          this.setGeneralProgress(true);

          await this.blockAdminAction(id);
          await this.loadAdminManagementAdmins();

          this.setSuccessNotification('Admin blocked');
          this.loader = false;
          this.setGeneralProgress(false);
        },
      });
    },

    async confirmUnblockAdmin() {
      const { id } = this.data;
      this.loader = true;
      this.setGeneralProgress(true);

      await this.unblockAdminAction(id);
      await this.loadAdminManagementAdmins();

      this.setSuccessNotification('Admin unblocked');
      this.loader = false;
      this.setGeneralProgress(false);
    },

    async removeNotificationsForCurrentAdmin(type) {
      this.loader = true;
      const { id } = this.getNotifications(type);
      await this.removeAdminNotificationsSettingsAction({ id });
      await this.loadAdminManagementAdmins();
      await this.loadAdminNotificationsSettings();
      this.telegramNotificationTypeList = this.getNotifications('telegram').adminNotificationTypeList || this.getNotifications('telegram').types || [];
      this.emailNotificationTypeList = this.getNotifications('email').adminNotificationTypeList || this.getNotifications('email').types || [];
      this.loader = false;
      this.setSuccessNotification('Notifications settings changed');
    },
    async applyNotificationsSettings(type) {
      const { emailNotificationTypeList, telegramNotificationTypeList, telegramId } = this;
      const { email } = this.data;
      let requestData = {
        userName: email,
        [this.$utils.getAppType() === 'p2pay' ? 'lang' : 'language']: this.constant.languages.LANGUAGES_LIST[this.data.language].code,
      };

      if (this.$utils.getAppType() !== 'p2pay') {
        requestData = {
          ...requestData,
          adminNotificationTypeList: type === 'email' ? emailNotificationTypeList : telegramNotificationTypeList,
        };
      } else {
        requestData = {
          ...requestData,
          types: type === 'email' ? emailNotificationTypeList : telegramNotificationTypeList,
        };
      }

      this.loader = true;
      this.setGeneralProgress(true);
      try {
        if (this.getNotifications(type).adminNotificationTypeList || this.getNotifications(type).types) {
          await this.editAdminNotificationsSettingsAction({
            id: this.getNotifications(type).id,
            ...requestData,
          });
        } else {
          await this.addAdminNotificationsSettingsAction({
            adminId: this.data.id,
            ...(type === 'email' ? { email } : { telegramId }),
            ...requestData,
          });
        }
        await this.loadAdminNotificationsSettings();
        this.loader = false;
        this.setGeneralProgress(false);
        this.setSuccessNotification('Notifications settings changed');
      } catch (error) {
        this.loader = false;
        this.setGeneralProgress(false);
        this.setErrorNotification(error.message);
      }
    },
  },
};
