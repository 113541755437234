//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import constant from '~/const';
import Filters from './AdminManagementLogFilters.vue';

import tableConfig from './tableConfig';

export default {
  components: { Filters },

  data() {
    return {
      tableConfig,
      constant,
      selection: [],
      loader: false,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('adminManagementLog', ['page', 'pages', 'data', 'sort']),
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader', 'setGeneralProgress', 'setSuccessNotification', 'setErrorNotification']),
    ...mapActions('adminManagementLog', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
    }),

    parseRowData(row) {
      if (row) return JSON.parse(row.data);
      return {};
    },

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      try {
        await this.loadDataAction(data);
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    getStatus(id) {
      const { ADMIN_STATUS_LIST } = constant.admin;
      return this.$utils.getCaption(ADMIN_STATUS_LIST, id);
    },
  },
};
